<template>
  <b-modal
    id="modalVoucher"
    lazy
    no-fade
    size="lg"
    :title="$t('vouchers.titles.viewVoucher') + modalData.code"
    @hidden="handleClose"
    @close="handleClose"
  >
    <b-form validate>
      <b-form-row>
        <b-form-group :label="$t('vouchers.inputs.code')" label-for="voucher-code" class="col-md-4 col-sm-12">
          <b-form-input 
            id="voucher-code" 
            v-model="modalData.code" 
            type="text" 
            :disabled="true"
          />
        </b-form-group>
        <b-form-group :label="$t('vouchers.inputs.partner')" label-for="voucher-partner" class="col-md-4 col-sm-12">
          <b-form-input 
            id="voucher-partner" 
            v-model="modalData.partner" 
            type="text" 
            :disabled="true"
          />
        </b-form-group>
        <b-form-group :label="$t('vouchers.inputs.category')" label-for="voucher-category" class="col-md-4 col-sm-12">
          <b-form-select 
            id="voucher-category" 
            :value="modalData.categoryId"
            :options="[ { value: modalData.categoryId, text: modalData.category.description} ]"
            :disabled="true" 
          />
        </b-form-group>
      </b-form-row>
      <b-form-row>
        <b-form-group :label="$t('vouchers.inputs.voucherType')" label-for="voucher-type" class="col-md-4 col-sm-12">
          <b-form-select
            id="voucher-type"
            :value="modalData.voucherType"
            :options="voucherTypeList"
            :disabled="true"
          />
        </b-form-group>
        <b-form-group :label="$t('vouchers.inputs.startDate')" label-for="voucher-startDate" class="col-md-4 col-sm-12">
          <date-picker
            id="voucher-startDate"
            v-model="modalData.startDate"
            :disabled="true" 
          />
        </b-form-group>
        <b-form-group :label="$t('vouchers.inputs.expirationDate')" label-for="voucher-expirationDate" class="col-md-4 col-sm-12">
          <date-picker
            id="voucher-expirationDate"
            v-model="modalData.expirationDate"
            :disabled="true" 
          />
        </b-form-group>
      </b-form-row>
      <b-form-row>
        <b-form-group :label="$t('vouchers.inputs.amountType')" label-for="voucher-amountType" class="col-md-4 col-sm-12">
          <b-form-select
            id="voucher-amountType"
            :value="modalData.amountType"
            :options="amountTypeList"
            :disabled="true"
          />
        </b-form-group>
        <mask-input 
          id="voucher-amount"
          v-model="modalData.amount"
          class="col-md-4 col-sm-12"
          :prepend="$t('payments.labels.currency')"
          :mask-label="$t('vouchers.inputs.amount')"
          :mask="['#####,##', '####,##', '###,##', '##,##', '#,##']"
          :disabled="true"
        />
        <mask-input
          id="voucher-percentageAmount"
          v-model="modalData.percentageAmount"
          class="col-md-4 col-sm-12"
          :prepend="'%'"
          :mask-label="$t('vouchers.inputs.percentageAmount')"
          :mask="[ '###', '##', '#' ]"
          :disabled="true"
        />
      </b-form-row>
      <b-form-row>
        <b-form-group :label="$t('vouchers.inputs.usageLimit')" label-for="voucher-usageLimit" class="col-md-4 col-sm-12">
          <b-form-input
            id="voucher-usageLimit"
            v-model="modalData.usageLimit"
            type="number"
            :disabled="true"
          />
        </b-form-group>
        <mask-input 
          id="voucher-minimumAmount"
          v-model="modalData.minimumAmount"
          class="col-md-4 col-sm-12"
          :prepend="$t('payments.labels.currency')"
          :mask-label="$t('vouchers.inputs.minimumAmount')"
          :mask="['#####,##', '####,##', '###,##', '##,##', '#,##']"
          :disabled="true"
        />
        <mask-input 
          id="voucher-maximumAmount"
          v-model="modalData.maximumAmount"
          class="col-md-4 col-sm-12"
          :prepend="$t('payments.labels.currency')"
          :mask-label="$t('vouchers.inputs.maximumAmount')"
          :mask="['#####,##', '####,##', '###,##', '##,##', '#,##']"
          :disabled="true"
        />
      </b-form-row>
      <b-form-row class="lines">
        <b-form-group
          :label="$t('vouchers.inputs.description')"
          label-class="font-weight-bold"
          label-for="voucher-description"
          class="col-md-12 col-sm-12 mb-0"
        >
          <b-form-textarea
            id="voucher-description"
            v-model="modalData.description"
            type="text"
            :disabled="true"
          />
        </b-form-group>
      </b-form-row>
      <b-form-row class="lines" v-if="isCustomerVoucher">
        <b-form-group
          :label="$t('vouchers.inputs.customerId')"
          label-class="font-weight-bold"
          label-for="voucher-customer"
          class="col-md-12 col-sm-12 mb-0 mt-3"
        >
          <router-link
            v-b-tooltip
            :title="$t('payments.labels.goToDriver')"
            :to="`/drivers/profile/${modalData.customerId}`"
          >
            {{ modalData.customerId }}
            <i class="fa fa-external-link" />
          </router-link>
        </b-form-group>
      </b-form-row>
    </b-form>
    <template slot="modal-footer">
      <b-button size="md" variant="danger" @click="handleClose()">
        {{ $t('vouchers.buttons.exit') }}
      </b-button>
    </template>
  </b-modal>
</template>
      
<script>  
  import { VOUCHER_TYPES_OPTIONS, VOUCHER_AMOUNT_TYPES_OPTIONS, VOUCHER_TYPES } from '@enums/vouchers';
  export default {
      name: 'ModalVoucher',
      model: {
        prop: 'handlerModal',
        event: 'change',
      },
      props: {
        modalData: {
          type: Object
        },
      },
      data () {
        return {};
      },
      computed: {
        voucherTypeList() {
          return VOUCHER_TYPES_OPTIONS;
        },
        amountTypeList() {
          return VOUCHER_AMOUNT_TYPES_OPTIONS;
        }, 
        isCustomerVoucher() {
          return this.modalData.voucherType === VOUCHER_TYPES.CUSTOMER
        }
      },
      watch: {},
      mounted () {},
      methods: {
        handleClose() {
          this.$root.$emit('bv::hide::modal', 'modalVoucher')
        }
      },
    }
</script>