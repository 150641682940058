var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Layout", [
    _c(
      "div",
      { staticClass: "animated fadeIn" },
      [
        _c(
          "b-row",
          { staticClass: "pb-4" },
          [
            _c("b-col", { attrs: { cols: "6" } }, [
              _c("h2", [_vm._v("Cupons")]),
            ]),
            _c(
              "b-col",
              { attrs: { cols: "6" } },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "float-right",
                    attrs: {
                      variant: "primary",
                      disabled: !_vm.enableNewVoucherButton,
                    },
                    on: { click: _vm.newVoucher },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("vouchers.buttons.newVoucher")) + " "
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "b-card",
          { attrs: { "body-class": "p-0" } },
          [
            _c(
              "b-card-body",
              { staticClass: "p-0 vouchers-table-format" },
              [
                _c("custom-data-table", {
                  ref: "vouchers_table",
                  attrs: {
                    fields: _vm.fields,
                    filters: _vm.filters,
                    query: _vm.voucherListQL,
                    "query-node": "listVouchers",
                  },
                  on: { "vuetable:row-clicked": _vm.openVoucher },
                  scopedSlots: _vm._u([
                    {
                      key: "partner",
                      fn: function (props) {
                        return _c("span", {}, [
                          _vm._v(" " + _vm._s(props.rowData.partner) + " "),
                        ])
                      },
                    },
                    {
                      key: "customerId",
                      fn: function (props) {
                        return _c(
                          "span",
                          {},
                          [
                            _vm.isCustomerVoucher(props.rowData)
                              ? _c(
                                  "router-link",
                                  {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip",
                                      },
                                    ],
                                    attrs: {
                                      title: _vm.$t(
                                        "payments.labels.goToDriver"
                                      ),
                                      to: `/drivers/profile/${props.rowData.customerId}`,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(props.rowData.customerId) +
                                        " "
                                    ),
                                    _c("i", {
                                      staticClass: "fa fa-external-link",
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            !_vm.isCustomerVoucher(props.rowData)
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.voucherTypeTranslated(
                                        props.rowData.voucherType
                                      )
                                    )
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        )
                      },
                    },
                    {
                      key: "code",
                      fn: function (props) {
                        return _c(
                          "span",
                          {},
                          [
                            _c("ticket-component", {
                              attrs: { code: props.rowData.code },
                            }),
                          ],
                          1
                        )
                      },
                    },
                    {
                      key: "description",
                      fn: function (props) {
                        return _c("span", {}, [
                          _vm._v(" " + _vm._s(props.rowData.description) + " "),
                        ])
                      },
                    },
                    {
                      key: "amount",
                      fn: function (props) {
                        return _c("span", {}, [
                          _vm._v(
                            " " + _vm._s(_vm.formatAmount(props.rowData)) + " "
                          ),
                        ])
                      },
                    },
                    {
                      key: "startDate",
                      fn: function (props) {
                        return _c("span", {}, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.formatDate(props.rowData.startDate)) +
                              " "
                          ),
                        ])
                      },
                    },
                    {
                      key: "expirationDate",
                      fn: function (props) {
                        return _c("span", {}, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.formatDate(props.rowData.expirationDate)
                              ) +
                              " "
                          ),
                        ])
                      },
                    },
                    {
                      key: "status",
                      fn: function (props) {
                        return _c(
                          "span",
                          {},
                          [
                            _c(
                              "b-badge",
                              {
                                attrs: {
                                  variant: _vm.statusVariantBadge(
                                    props.rowData.status
                                  ),
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.statusTranslated(props.rowData.status)
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      },
                    },
                  ]),
                }),
                _c("modal-new-voucher", {
                  attrs: { "all-categories": _vm.voucherCategoriesList || [] },
                  on: {
                    success: function ($event) {
                      return _vm.refreshData()
                    },
                  },
                }),
                _c("modal-voucher", { attrs: { "modal-data": _vm.modalData } }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }