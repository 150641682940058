var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modalVoucher",
        lazy: "",
        "no-fade": "",
        size: "lg",
        title: _vm.$t("vouchers.titles.viewVoucher") + _vm.modalData.code,
      },
      on: { hidden: _vm.handleClose, close: _vm.handleClose },
    },
    [
      _c(
        "b-form",
        { attrs: { validate: "" } },
        [
          _c(
            "b-form-row",
            [
              _c(
                "b-form-group",
                {
                  staticClass: "col-md-4 col-sm-12",
                  attrs: {
                    label: _vm.$t("vouchers.inputs.code"),
                    "label-for": "voucher-code",
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: { id: "voucher-code", type: "text", disabled: true },
                    model: {
                      value: _vm.modalData.code,
                      callback: function ($$v) {
                        _vm.$set(_vm.modalData, "code", $$v)
                      },
                      expression: "modalData.code",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  staticClass: "col-md-4 col-sm-12",
                  attrs: {
                    label: _vm.$t("vouchers.inputs.partner"),
                    "label-for": "voucher-partner",
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "voucher-partner",
                      type: "text",
                      disabled: true,
                    },
                    model: {
                      value: _vm.modalData.partner,
                      callback: function ($$v) {
                        _vm.$set(_vm.modalData, "partner", $$v)
                      },
                      expression: "modalData.partner",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  staticClass: "col-md-4 col-sm-12",
                  attrs: {
                    label: _vm.$t("vouchers.inputs.category"),
                    "label-for": "voucher-category",
                  },
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      id: "voucher-category",
                      value: _vm.modalData.categoryId,
                      options: [
                        {
                          value: _vm.modalData.categoryId,
                          text: _vm.modalData.category.description,
                        },
                      ],
                      disabled: true,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-form-row",
            [
              _c(
                "b-form-group",
                {
                  staticClass: "col-md-4 col-sm-12",
                  attrs: {
                    label: _vm.$t("vouchers.inputs.voucherType"),
                    "label-for": "voucher-type",
                  },
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      id: "voucher-type",
                      value: _vm.modalData.voucherType,
                      options: _vm.voucherTypeList,
                      disabled: true,
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  staticClass: "col-md-4 col-sm-12",
                  attrs: {
                    label: _vm.$t("vouchers.inputs.startDate"),
                    "label-for": "voucher-startDate",
                  },
                },
                [
                  _c("date-picker", {
                    attrs: { id: "voucher-startDate", disabled: true },
                    model: {
                      value: _vm.modalData.startDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.modalData, "startDate", $$v)
                      },
                      expression: "modalData.startDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  staticClass: "col-md-4 col-sm-12",
                  attrs: {
                    label: _vm.$t("vouchers.inputs.expirationDate"),
                    "label-for": "voucher-expirationDate",
                  },
                },
                [
                  _c("date-picker", {
                    attrs: { id: "voucher-expirationDate", disabled: true },
                    model: {
                      value: _vm.modalData.expirationDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.modalData, "expirationDate", $$v)
                      },
                      expression: "modalData.expirationDate",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-form-row",
            [
              _c(
                "b-form-group",
                {
                  staticClass: "col-md-4 col-sm-12",
                  attrs: {
                    label: _vm.$t("vouchers.inputs.amountType"),
                    "label-for": "voucher-amountType",
                  },
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      id: "voucher-amountType",
                      value: _vm.modalData.amountType,
                      options: _vm.amountTypeList,
                      disabled: true,
                    },
                  }),
                ],
                1
              ),
              _c("mask-input", {
                staticClass: "col-md-4 col-sm-12",
                attrs: {
                  id: "voucher-amount",
                  prepend: _vm.$t("payments.labels.currency"),
                  "mask-label": _vm.$t("vouchers.inputs.amount"),
                  mask: ["#####,##", "####,##", "###,##", "##,##", "#,##"],
                  disabled: true,
                },
                model: {
                  value: _vm.modalData.amount,
                  callback: function ($$v) {
                    _vm.$set(_vm.modalData, "amount", $$v)
                  },
                  expression: "modalData.amount",
                },
              }),
              _c("mask-input", {
                staticClass: "col-md-4 col-sm-12",
                attrs: {
                  id: "voucher-percentageAmount",
                  prepend: "%",
                  "mask-label": _vm.$t("vouchers.inputs.percentageAmount"),
                  mask: ["###", "##", "#"],
                  disabled: true,
                },
                model: {
                  value: _vm.modalData.percentageAmount,
                  callback: function ($$v) {
                    _vm.$set(_vm.modalData, "percentageAmount", $$v)
                  },
                  expression: "modalData.percentageAmount",
                },
              }),
            ],
            1
          ),
          _c(
            "b-form-row",
            [
              _c(
                "b-form-group",
                {
                  staticClass: "col-md-4 col-sm-12",
                  attrs: {
                    label: _vm.$t("vouchers.inputs.usageLimit"),
                    "label-for": "voucher-usageLimit",
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "voucher-usageLimit",
                      type: "number",
                      disabled: true,
                    },
                    model: {
                      value: _vm.modalData.usageLimit,
                      callback: function ($$v) {
                        _vm.$set(_vm.modalData, "usageLimit", $$v)
                      },
                      expression: "modalData.usageLimit",
                    },
                  }),
                ],
                1
              ),
              _c("mask-input", {
                staticClass: "col-md-4 col-sm-12",
                attrs: {
                  id: "voucher-minimumAmount",
                  prepend: _vm.$t("payments.labels.currency"),
                  "mask-label": _vm.$t("vouchers.inputs.minimumAmount"),
                  mask: ["#####,##", "####,##", "###,##", "##,##", "#,##"],
                  disabled: true,
                },
                model: {
                  value: _vm.modalData.minimumAmount,
                  callback: function ($$v) {
                    _vm.$set(_vm.modalData, "minimumAmount", $$v)
                  },
                  expression: "modalData.minimumAmount",
                },
              }),
              _c("mask-input", {
                staticClass: "col-md-4 col-sm-12",
                attrs: {
                  id: "voucher-maximumAmount",
                  prepend: _vm.$t("payments.labels.currency"),
                  "mask-label": _vm.$t("vouchers.inputs.maximumAmount"),
                  mask: ["#####,##", "####,##", "###,##", "##,##", "#,##"],
                  disabled: true,
                },
                model: {
                  value: _vm.modalData.maximumAmount,
                  callback: function ($$v) {
                    _vm.$set(_vm.modalData, "maximumAmount", $$v)
                  },
                  expression: "modalData.maximumAmount",
                },
              }),
            ],
            1
          ),
          _c(
            "b-form-row",
            { staticClass: "lines" },
            [
              _c(
                "b-form-group",
                {
                  staticClass: "col-md-12 col-sm-12 mb-0",
                  attrs: {
                    label: _vm.$t("vouchers.inputs.description"),
                    "label-class": "font-weight-bold",
                    "label-for": "voucher-description",
                  },
                },
                [
                  _c("b-form-textarea", {
                    attrs: {
                      id: "voucher-description",
                      type: "text",
                      disabled: true,
                    },
                    model: {
                      value: _vm.modalData.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.modalData, "description", $$v)
                      },
                      expression: "modalData.description",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.isCustomerVoucher
            ? _c(
                "b-form-row",
                { staticClass: "lines" },
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "col-md-12 col-sm-12 mb-0 mt-3",
                      attrs: {
                        label: _vm.$t("vouchers.inputs.customerId"),
                        "label-class": "font-weight-bold",
                        "label-for": "voucher-customer",
                      },
                    },
                    [
                      _c(
                        "router-link",
                        {
                          directives: [
                            { name: "b-tooltip", rawName: "v-b-tooltip" },
                          ],
                          attrs: {
                            title: _vm.$t("payments.labels.goToDriver"),
                            to: `/drivers/profile/${_vm.modalData.customerId}`,
                          },
                        },
                        [
                          _vm._v(" " + _vm._s(_vm.modalData.customerId) + " "),
                          _c("i", { staticClass: "fa fa-external-link" }),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "template",
        { slot: "modal-footer" },
        [
          _c(
            "b-button",
            {
              attrs: { size: "md", variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.handleClose()
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("vouchers.buttons.exit")) + " ")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }