<template>
  <b-modal
    id="modalNewVoucher"
    lazy
    no-fade
    size="lg"
    :title="$t('vouchers.titles.newVoucher')"
    @hidden="handleClose"
  >
    <b-form validate>
      <b-form-row>
        <b-form-group
          :label="$t('vouchers.inputs.code')"
          :state="!$v.code.$error"
          label-for="voucher-code"
          class="col-md-4 col-sm-12"
        >
          <div class="voucher-code-group">
            <b-form-input id="voucher-code" v-model="code" type="text" />
            <b-button
              class="voucher-code-button"
              variant="info"
              @click="generateCode"
            >
            {{ $t('vouchers.buttons.generate') }}
            </b-button>
          </div>
          <b-form-invalid-feedback
            v-if="!$v.code.minLength"
            :force-show="!$v.code.minLength"
            v-html="`Tamanho mínimo ${$v.code.$params.minLength.min} caracteres`"
          />
          <b-form-invalid-feedback
            v-if="!$v.code.maxLength"
            :force-show="!$v.code.maxLength"
            v-html="`Tamanho máximo ${$v.code.$params.maxLength.max} caracteres`"
          />
          <b-form-invalid-feedback
            v-if="!$v.code.alphaNum"
            :force-show="!$v.code.alphaNum"
            v-html="'Utilize somente letras e números'"
          />
        </b-form-group>
        <b-form-group 
          :label="$t('vouchers.inputs.partner')"
          :state="!$v.partner.$error"
          label-for="voucher-partner"
          class="col-md-4 col-sm-12"
        >
          <b-form-input id="voucher-partner" v-model="partner" type="text" />
        </b-form-group>
        <b-form-group
          :label="$t('vouchers.inputs.category')"
          :state="!$v.category.$error"
          label-for="voucher-category"
          class="col-md-4 col-sm-12"
        >
          <b-form-select id="voucher-category" v-model="category" :options="categoryList" />
        </b-form-group>
      </b-form-row>
      <b-form-row>
        <b-form-group
          :label="$t('vouchers.inputs.voucherType')"
          :state="!$v.voucherType.$error"
          label-for="voucher-type"
          class="col-md-4 col-sm-12"
        >
          <b-form-select id="voucher-type" v-model="voucherType" :options="voucherTypeList" />
        </b-form-group>
        <b-form-group
          v-if="voucherType === customerVoucherType"
          :label="$t('vouchers.inputs.customerNationalId')"
          :state="!$v.voucherCustomer.$error"
          label-for="voucher-customer"
          class="col-md-8 col-sm-12"
        >
          <the-mask
            id="voucher-customer"
            v-model.trim="customerNationalId"
            type="text"
            class="form-control"
            mask="###.###.###-##"
            v-on:input="searchCustomer"
          />
          <div class="customer-name" v-if="customerName">{{ customerName }}</div>
          <div class="customer-name" v-if="customerLoading">{{ $t('vouchers.messages.searchingCustomer') }}</div>
          <div class="customer-name" v-if="customerNotFound">{{ $t('vouchers.messages.customerNotFound') }}</div>
        </b-form-group>
        <b-form-group
          :label="$t('vouchers.inputs.startDate')"
          :state="!$v.startDate.$error"
          label-for="voucher-startDate"
          class="col-md-4 col-sm-12"
        >
          <date-picker
            id="voucher-startDate"
            v-model="startDate"
            :not-before="$moment().format('YYYY-MM-DD')"
            :hide="false"
          />
        </b-form-group>
        <b-form-group
          :label="$t('vouchers.inputs.expirationDate')"
          :state="!$v.expirationDate.$error"
          label-for="voucher-expirationDate"
          class="col-md-4 col-sm-12"
        >
          <date-picker
            id="voucher-expirationDate"
            v-model="expirationDate"
            :not-before="$moment(startDate || $moment()).format('YYYY-MM-DD')"
            :hide="!startDate"
          />
        </b-form-group>
      </b-form-row>
      <b-form-row>
        <b-form-group 
          :label="$t('vouchers.inputs.amountType')"
          :state="!$v.amountType.$error"
          label-for="voucher-amountType"
          class="col-md-4 col-sm-12"
        >
          <b-form-select id="voucher-amountType" v-model="amountType" :options="amountTypeList" />
        </b-form-group>
        <mask-input 
          id="voucher-amount"
          v-model="amount"
          parent-class="col-md-4 col-sm-12 mask-style"
          type="number"
          :prepend="$t('payments.labels.currency')"
          :mask-label="$t('vouchers.inputs.amount')"
          :mask="['#####,##', '####,##', '###,##', '##,##', '#,##']"
          :disabled="!amountType || !isFixedValue"
          :field-state="!$v.amount.$error"
        />
        <mask-input
          id="voucher-percentageAmount"
          v-model="percentageAmount"
          parent-class="col-md-4 col-sm-12 mask-style"
          :append="'%'"
          :mask-label="$t('vouchers.inputs.percentageAmount')"
          :mask="[ '###', '##', '#' ]"
          :disabled="!amountType || isFixedValue"
          :field-state="!$v.percentageAmount.$error"
        />
      </b-form-row>
      <b-form-row>
        <b-form-group
          :label="$t('vouchers.inputs.usageLimit')"
          :state="!$v.usageLimit.$error"
          label-for="voucher-usageLimit"
          class="col-md-4 col-sm-12"
        >
          <b-form-input id="voucher-usageLimit" v-model="usageLimit" type="number" />
        </b-form-group>
        <mask-input 
          id="voucher-minimumAmount"
          v-model="minimumAmount"
          parent-class="col-md-4 col-sm-12 mask-style"
          type="number"
          :prepend="$t('payments.labels.currency')"
          :mask-label="$t('vouchers.inputs.minimumAmount')"
          :mask="['#####,##', '####,##', '###,##', '##,##', '#,##']"
          :disabled="!amountType"
          :field-state="!$v.minimumAmount.$error"
        />
        <mask-input 
          id="voucher-maximumAmount"
          v-model="maximumAmount"
          parent-class="col-md-4 col-sm-12 mask-style"
          :prepend="$t('payments.labels.currency')"
          :mask-label="$t('vouchers.inputs.maximumAmount')"
          :mask="['#####,##', '####,##', '###,##', '##,##', '#,##']"
          :disabled="!amountType || isFixedValue"
          :field-state="!$v.maximumAmount.$error"
        />
      </b-form-row>
      <b-form-row class="lines">
        <b-form-group
          :label="$t('vouchers.inputs.description')"
          label-class="font-weight-bold"
          label-for="voucher-description"
          class="col-md-12 col-sm-12 mb-0"
          :state="!$v.description.$error"
        >
          <b-form-textarea
            id="voucher-description"
            v-model="description"
            type="text"
            :rows="1"
            :max-rows="4"
            :required="true"
          />
          <b-form-invalid-feedback
            v-if="!$v.description.minLength"
            :force-show="!$v.description.minLength"
            v-html="`Tamanho mínimo ${$v.description.$params.minLength.min} caracteres`"
          />
          <b-form-invalid-feedback
            v-if="!$v.description.maxLength"
            :force-show="!$v.description.maxLength"
            v-html="`Tamanho máximo ${$v.description.$params.maxLength.max} caracteres`"
          />
        </b-form-group>
      </b-form-row>
    </b-form>
    <template slot="modal-footer">
      <b-button size="md" variant="danger" @click="handleClose()">
        {{ $t('vouchers.buttons.cancel') }}
      </b-button>
      <b-button size="md" variant="success" @click="handleSubmit()">
        {{ $t('vouchers.buttons.save') }}
      </b-button>
    </template>
  </b-modal>
</template>
    
<script>
    import CREATE_VOUCHER from '@graphql/vouchers/create.gql';
    import { makeMutation } from '@graphql/middleware';
    import listDrivers from '@graphql/driver/queries/list.gql';
    import { VOUCHER_TYPES, VOUCHER_TYPES_OPTIONS, VOUCHER_AMOUNT_TYPES_OPTIONS, VOUCHER_AMOUNT_TYPES  } from '@enums/vouchers';

    import { validationMixin } from 'vuelidate';
    import { required, integer, alphaNum, minLength, maxLength, maxValue, minValue } from 'vuelidate/lib/validators';

    export default {
      name: 'ModalNewVoucher',
      mixins: [validationMixin],
      components: {
        InputSelectSearch: () => import('@components/shared/input-select-search'),
      },
      props: {
        allCategories: {
          type: Array,
          required: true,
        },
      },
      data () {
        return {
            customerLoading: false,
            customerNotFound: false,
            customerName: null,
            customerId: null,
            customerNationalId: null,
            customerVoucherType: VOUCHER_TYPES.CUSTOMER,
            code: null,
            voucherType: null,
            category: null,
            partner: null,
            amount: null,
            startDate: null,
            expirationDate: null,
            description: null,
            amountType: null,
            percentageAmount: null,
            usageLimit: null,
            minimumAmount: null,
            maximumAmount: null,
        };
      },
      validations() {
        return {
          code: {
            required,
            alphaNum,
            minLength: minLength(10),
            maxLength: maxLength(40),
          },
          partner: {
            required,
          },
          category: {
            required,
          },
          voucherType: {
            required,
          },
          startDate: {
            required,
          },
          expirationDate: {
            required,
          },
          voucherCustomer: {
            required: this.voucherType === this.customerVoucherType && !this.customerId,
          },
          amountType: {
            required,
          },
          amount: {
            required: this.amountType === VOUCHER_AMOUNT_TYPES.VALUE,
            integer,
            minValue: minValue(this.amountType === VOUCHER_AMOUNT_TYPES.VALUE ? 10 : 0),
          },
          percentageAmount: {
            required: this.amountType === VOUCHER_AMOUNT_TYPES.PERCENT,
            integer,
            minValue: minValue(this.amountType === VOUCHER_AMOUNT_TYPES.PERCENT ? 1 : 0),
            maxValue: maxValue(100)
          },
          usageLimit: {
            required,
            integer,
            minValue: minValue(1),
          },
          minimumAmount: {
            required,
            integer,
            minValue: minValue(this.amountType === VOUCHER_AMOUNT_TYPES.VALUE ? this.amount : 0),
          },
          maximumAmount: {
            required,
            integer,
            minValue: minValue(100)
          },
          description: {
            required,
            maxLength: maxLength(150),
            minLength: minLength(10)
          },
        }
      },
      computed: {
        categoryList() {
          return this.allCategories
            .filter(category => category.deleted === false)
            .map(category => ({ value: category.id, text: category.description }));
        },
        voucherTypeList() {
          return VOUCHER_TYPES_OPTIONS;
        },
        amountTypeList() {
          return VOUCHER_AMOUNT_TYPES_OPTIONS;
        },
      },
      watch: {
        voucherType: {
          handler() {
            this.customerName = '';
            this.customerId = null;
            this.customerNationalId = null;
          },
        },
        code: {
          handler() { this.code = this.code.toUpperCase() },
        },
        startDate: {
          handler() { this.resetExpirationDate() },
        },
        amountType: {
          handler() { this.releaseAmountType() },
        },
        amount: {
          handler() { this.setMaximumAmount() },
        },
      },
      methods: {
        searchCustomer() {
          this.customerName = '';
          this.customerId = null;
          this.customerNotFound = false;

          const cpf = this.customerNationalId.replace(/[^0-9]/g, '');

          if (cpf.length < 11 || this.customerLoading) {
            return;
          }

          this.customerLoading = true;

          return this.$apollo
            .query({
              query: listDrivers,
              variables: { filters: { where: { $and: [{ cpf }] } }, limit: 1, page: 0 },
              fetchPolicy: 'network-only',
            })
            .then(({ data }) => {
              if (data.drivers.items.length > 0) {
                const [driver] = data.drivers.items;
                this.customerId = driver.id
                this.customerName = driver.name
                this.customerNotFound = false;
              } else {
                this.customerNotFound = true;
              }
            }).catch((err) => {
              this.customerName = '';
              throw err;
            }).finally(() => {
              this.customerLoading = false;
            });
        },
        handleClose() {
            this.code = null;
            this.voucherType = null;
            this.category = null;
            this.partner = null;
            this.amount = null;
            this.startDate = null;
            this.expirationDate = null;
            this.description = null;
            this.amountType = null;
            this.percentageAmount = null;
            this.usageLimit = null;
            this.minimumAmount = null;
            this.maximumAmount = null;
            this.$v.$reset();
            this.$root.$emit('bv::hide::modal', 'modalNewVoucher');
        },
        handleSubmit() {
          this.validateNummericFields()
          this.$v.$touch()

          if (this.voucherType === this.customerVoucherType && !this.customerId) {
            return;
          }

          if (!this.$v.$invalid) {
            const confirmationWindow = this.$swal.mixin({
              confirmButtonClass: 'btn btn-success',
              cancelButtonClass: 'btn btn-danger mr-3',
              buttonsStyling: false,
            });
            confirmationWindow({
              title: this.$t('vouchers.titles.confirmCreateVoucher'),
              type: 'warning',
              showCancelButton: true,
              confirmButtonText: this.$t('vouchers.buttons.confirm'),
              cancelButtonText: this.$t('vouchers.buttons.cancel'),
              reverseButtons: true,
            }).then(this.createCupom);
          }
        },
        generateCode() {
          const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
          const length = 12

          this.code = Array(length)
            .fill(0)
            .map(() => characters.charAt(Math.floor(Math.random() * characters.length)))
            .join('')
        },
        createCupom({ value: doCreate }) {
          if (!doCreate) return;
          const voucherInput = {
            code: this.code,
            voucherType: this.voucherType,
            categoryId: this.category ,
            partner: this.partner,
            customerNationalId: this.voucherType === VOUCHER_TYPES.CUSTOMER ? this.customerNationalId : null,
            amount: Number(this.amount),
            startDate: this.startDate,
            expirationDate: this.expirationDate,
            description: this.description,
            amountType: this.amountType,
            percentageAmount: Number(this.percentageAmount),
            usageLimit: Number(this.usageLimit),
            minimumAmount: Number(this.minimumAmount),
            maximumAmount: Number(this.maximumAmount),
            customerId: this.customerId,
          };
          makeMutation(CREATE_VOUCHER, voucherInput).then(() => {
            this.$swal({
                type: 'success',
                title: this.$t('vouchers.messages.successOnCreateVoucher'),
                showConfirmButton: true,
                timer: 15000,
            });
            this.$emit('success')
            }).catch(() => {
                this.$swal({
                type: 'error',
                title: this.$t('vouchers.messages.errorOnCreateVoucher'),
                showConfirmButton: true,
                timer: 15000,
            });
          })
        },
        checkField(field) {
            return this.validation ? !!field : null;
        },
        resetExpirationDate() {
          if (this.$moment(this.startDate).isAfter(this.$moment(this.expirationDate))) {
            this.expirationDate = null;
          }
        },
        releaseAmountType() {
          if (this.amountType === VOUCHER_AMOUNT_TYPES.PERCENT) {
            this.isFixedValue = false;
            this.amount = 0;
          } else {
            this.isFixedValue = true;
            this.percentageAmount = 0;
            this.maximumAmount = this.amount;
          }
        },
        setMaximumAmount() {
          if (this.amountType === VOUCHER_AMOUNT_TYPES.VALUE) {
            this.maximumAmount = this.amount;
          }
        },
        validateNummericFields() {
          if (!this.isNumber(this.amount)) this.amount = null;
          if (!this.isNumber(this.percentageAmount)) this.percentageAmount = null;
          if (!this.isNumber(this.usageLimit)) this.usageLimit = null;
          if (!this.isNumber(this.minimumAmount)) this.minimumAmount = null;
          if (!this.isNumber(this.maximumAmount)) this.maximumAmount = null;
        },
        isNumber(val) {
          return !isNaN(val);
        },
      },
    }
</script>

<style lang="scss">
  .mask-style .col-form-label {
    line-height: 17.8px;
  }

  .customer-name {
    margin-top: 8px;
    color: rgba(0, 0, 0, 0.6);
  }

  .voucher-code-group {
    position: relative;
  }

  #voucher-code {
    padding-right: 40px;
  }

  .voucher-code-button {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    font-size: 10px;
    border-radius: 0 4px 4px 0;
    color: white;
    font-weight: 700;
  }
</style>