<template>
  <Layout>
    <div class="animated fadeIn">
      <b-row class="pb-4">
        <b-col cols="6">
          <h2>Cupons</h2>
        </b-col>
        <b-col cols="6">
          <b-button 
            variant="primary"
            class="float-right" 
            :disabled="!enableNewVoucherButton"
            @click="newVoucher"
          >
            {{ $t('vouchers.buttons.newVoucher') }}
          </b-button>
        </b-col>
      </b-row>
      <b-card body-class="p-0">
        <b-card-body class="p-0 vouchers-table-format">
          <custom-data-table 
            ref="vouchers_table" 
            :fields="fields" 
            :filters="filters"
            :query="voucherListQL" 
            :query-node="'listVouchers'"
            @vuetable:row-clicked="openVoucher"
          >
            <span slot="partner" slot-scope="props"> {{ props.rowData.partner }} </span>
            <span slot="customerId" slot-scope="props">
              <router-link
                v-if="isCustomerVoucher(props.rowData)"
                v-b-tooltip
                :title="$t('payments.labels.goToDriver')"
                :to="`/drivers/profile/${props.rowData.customerId}`"
              >
                {{ props.rowData.customerId }}
                <i class="fa fa-external-link" />
              </router-link>
              <span v-if="!isCustomerVoucher(props.rowData)">{{ voucherTypeTranslated(props.rowData.voucherType) }}</span>
            </span>
            <span slot="code" slot-scope="props"> <ticket-component :code="props.rowData.code" /> </span>
            <span slot="description" slot-scope="props"> {{ props.rowData.description }} </span>
            <span slot="amount" slot-scope="props"> {{ formatAmount(props.rowData) }} </span>
            <span slot="startDate" slot-scope="props"> {{ formatDate(props.rowData.startDate) }} </span>
            <span slot="expirationDate" slot-scope="props"> {{ formatDate(props.rowData.expirationDate) }} </span>
            <span slot="status" slot-scope="props">
              <b-badge :variant="statusVariantBadge(props.rowData.status)">
                {{ statusTranslated(props.rowData.status) }}
              </b-badge>
            </span>
          </custom-data-table>
          <modal-new-voucher :all-categories="voucherCategoriesList || []" @success="refreshData()" />
          <modal-voucher 
            :modal-data="modalData"
          />
        </b-card-body>
      </b-card>
    </div>
  </Layout>
</template>
<script>
  import { mapGetters } from 'vuex';
  import Layout from '@layouts/main';   
  import CustomDataTable from '@components/CustomDataTable';
  import TicketComponent from '@components/ticket';
  import ModalNewVoucher from '@components/modals/vouchers/new-voucher';
  import ModalVoucher from '@components/modals/vouchers/view-voucher';

  import { VOUCHER_AMOUNT_TYPES, VOUCHER_TYPES } from '@enums/vouchers';
  import { formatPrice } from '@utils/numbers';
  
  import VOUCHER_LIST from '@graphql/vouchers/list.gql';
  import VOUCHER_CATEGORIES_LIST from '@graphql/voucher-categories/list.gql';

  export default {
    name: 'Adminvouchers',
    page: {
      title: 'Administrar Cupons',
    },
    components: {
      Layout,
      CustomDataTable,
      TicketComponent,
      ModalNewVoucher,
      ModalVoucher,
    },
    data() {
      return {
        fields: [
          {
            name: 'partner',
            title: 'Parceiro',
            width: '2%',
          },
          {
            name: 'customerId',
            title: 'Motorista',
          },
          {
            name: 'code',
            title: 'Codigo',
            sortField: 'code',
          },
          {
            name: 'description',
            title: 'Descrição',
          },
          {
            name: 'amount',
            title: 'Valor do desconto',
          },
          {
            name: `startDate`,
            title: 'Disponivel para uso em',
            sortField: 'code',
          },
          {
            name: `expirationDate`,
            title: 'Expira em',
            sortField: 'code',
          },
          {
            name: 'status',
            title: 'Status',
            class: 'text-right',
          },
        ],
        voucherListQL: VOUCHER_LIST,
        modalData: {
          code: null,
          voucherType: null,
          categoryId: null,
          customerId: null,
          partner: null,
          amount: null,
          startDate: null,
          expirationDate: null,
          description: null,
          amountType: null,
          percentageAmount: null,
          usageLimit: null,
          minimumAmount: null,
          maximumAmount: null,
          category: null,
        },
      };
    },
    computed: {
      ...mapGetters('user', ['attributes', 'findGroups']),
      enableNewVoucherButton() {
        const loading = this.$apollo.queries.voucherCategoriesList.loading;
        const hasPermission = this.findGroups('RULE:VOUCHERS:CREATE') || this.findGroups('ACL:GOD');
        return !loading && hasPermission;
      },
      filters() {
        return {
          voucherType: {
            type: 'select',
            value: null,
            props: {
              label: 'Tipo de Cupom',
              options: [
              { text: 'Todos', value: null },
              { text: this.$t('vouchers.enums.voucherType.GLOBAL'), value: VOUCHER_TYPES.GLOBAL },
              { text: this.$t('vouchers.enums.voucherType.CUSTOMER'), value: VOUCHER_TYPES.CUSTOMER },
              ],
            },
            valueParserType: String,
            graphqlQuery(voucherType) {
              return voucherType ? { voucherType } : null;
            },
          },
          code: {
            type: 'textfield',
            props: {
              label: 'Código de desconto',
              placeholder: 'Digite um código',
            },
            valueParserType: String,
            graphqlQuery(code) {
              return code ? { code } : null;
            },
          },
          customerId: {
            type: 'textfield',
            props: {
              label: 'Motorista',
              placeholder: 'ID do motorista',
            },
            valueParserType: String,
            graphqlQuery(customerId) {
              return customerId ? { customerId } : null;
            },
            },
          };
      },
    },
    apollo: {
      voucherCategoriesList: {
        query: VOUCHER_CATEGORIES_LIST,
        update: data => { return data.listAllCategories.items },
      },
    },
    methods: {
      refreshData() {
        this.$root.$emit('bv::hide::modal', 'modalNewVoucher');
        this.$refs.vouchers_table.$_refresh();
      },
      newVoucher() {
        this.voucherModalCode = null;
        this.$root.$emit('bv::show::modal', 'modalNewVoucher');
      },
      openVoucher({ data }) {
        this.modalData = data;
        this.modalData.category = this.voucherCategoriesList.find(category => category.id === data.categoryId);
        this.$root.$emit('bv::show::modal', 'modalVoucher');
      },
      formatDate(date) {
        return this.$moment(date).format('DD/MM/YYYY');
      },
      formatAmount({amount, percentageAmount, amountType}) {
        if (amountType == VOUCHER_AMOUNT_TYPES.PERCENT) {
          return `${percentageAmount}% OFF`;
        }
        return formatPrice('R$', amount);
      },
      statusVariantBadge(status) {
            if (status === 'ACTIVE') return 'success';
            if (status === 'PAUSED') return 'warning';
            if (status === 'DELETED') return 'danger';
            if (status === 'FULL_USED') return 'danger';
            if (status === 'EXPIRED') return 'danger';
      },
      statusTranslated(status) {
        return this.$t(`vouchers.status.${status}`);
      },
      isCustomerVoucher(voucher) {
        return voucher.voucherType === VOUCHER_TYPES.CUSTOMER
      },
      voucherTypeTranslated(type) {
        return this.$t(`vouchers.enums.voucherType.${type}`);
      },
    },
  };
</script>
  
<style>
  .vouchers-table-format .custom-data-table .custom-data-table__filters {
    margin: 20px 20px 10px 20px !important;
  }
</style>  