var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modalNewVoucher",
        lazy: "",
        "no-fade": "",
        size: "lg",
        title: _vm.$t("vouchers.titles.newVoucher"),
      },
      on: { hidden: _vm.handleClose },
    },
    [
      _c(
        "b-form",
        { attrs: { validate: "" } },
        [
          _c(
            "b-form-row",
            [
              _c(
                "b-form-group",
                {
                  staticClass: "col-md-4 col-sm-12",
                  attrs: {
                    label: _vm.$t("vouchers.inputs.code"),
                    state: !_vm.$v.code.$error,
                    "label-for": "voucher-code",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "voucher-code-group" },
                    [
                      _c("b-form-input", {
                        attrs: { id: "voucher-code", type: "text" },
                        model: {
                          value: _vm.code,
                          callback: function ($$v) {
                            _vm.code = $$v
                          },
                          expression: "code",
                        },
                      }),
                      _c(
                        "b-button",
                        {
                          staticClass: "voucher-code-button",
                          attrs: { variant: "info" },
                          on: { click: _vm.generateCode },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("vouchers.buttons.generate")) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  !_vm.$v.code.minLength
                    ? _c("b-form-invalid-feedback", {
                        attrs: { "force-show": !_vm.$v.code.minLength },
                        domProps: {
                          innerHTML: _vm._s(
                            `Tamanho mínimo ${_vm.$v.code.$params.minLength.min} caracteres`
                          ),
                        },
                      })
                    : _vm._e(),
                  !_vm.$v.code.maxLength
                    ? _c("b-form-invalid-feedback", {
                        attrs: { "force-show": !_vm.$v.code.maxLength },
                        domProps: {
                          innerHTML: _vm._s(
                            `Tamanho máximo ${_vm.$v.code.$params.maxLength.max} caracteres`
                          ),
                        },
                      })
                    : _vm._e(),
                  !_vm.$v.code.alphaNum
                    ? _c("b-form-invalid-feedback", {
                        attrs: { "force-show": !_vm.$v.code.alphaNum },
                        domProps: {
                          innerHTML: _vm._s("Utilize somente letras e números"),
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  staticClass: "col-md-4 col-sm-12",
                  attrs: {
                    label: _vm.$t("vouchers.inputs.partner"),
                    state: !_vm.$v.partner.$error,
                    "label-for": "voucher-partner",
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: { id: "voucher-partner", type: "text" },
                    model: {
                      value: _vm.partner,
                      callback: function ($$v) {
                        _vm.partner = $$v
                      },
                      expression: "partner",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  staticClass: "col-md-4 col-sm-12",
                  attrs: {
                    label: _vm.$t("vouchers.inputs.category"),
                    state: !_vm.$v.category.$error,
                    "label-for": "voucher-category",
                  },
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      id: "voucher-category",
                      options: _vm.categoryList,
                    },
                    model: {
                      value: _vm.category,
                      callback: function ($$v) {
                        _vm.category = $$v
                      },
                      expression: "category",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-form-row",
            [
              _c(
                "b-form-group",
                {
                  staticClass: "col-md-4 col-sm-12",
                  attrs: {
                    label: _vm.$t("vouchers.inputs.voucherType"),
                    state: !_vm.$v.voucherType.$error,
                    "label-for": "voucher-type",
                  },
                },
                [
                  _c("b-form-select", {
                    attrs: { id: "voucher-type", options: _vm.voucherTypeList },
                    model: {
                      value: _vm.voucherType,
                      callback: function ($$v) {
                        _vm.voucherType = $$v
                      },
                      expression: "voucherType",
                    },
                  }),
                ],
                1
              ),
              _vm.voucherType === _vm.customerVoucherType
                ? _c(
                    "b-form-group",
                    {
                      staticClass: "col-md-8 col-sm-12",
                      attrs: {
                        label: _vm.$t("vouchers.inputs.customerNationalId"),
                        state: !_vm.$v.voucherCustomer.$error,
                        "label-for": "voucher-customer",
                      },
                    },
                    [
                      _c("the-mask", {
                        staticClass: "form-control",
                        attrs: {
                          id: "voucher-customer",
                          type: "text",
                          mask: "###.###.###-##",
                        },
                        on: { input: _vm.searchCustomer },
                        model: {
                          value: _vm.customerNationalId,
                          callback: function ($$v) {
                            _vm.customerNationalId =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "customerNationalId",
                        },
                      }),
                      _vm.customerName
                        ? _c("div", { staticClass: "customer-name" }, [
                            _vm._v(_vm._s(_vm.customerName)),
                          ])
                        : _vm._e(),
                      _vm.customerLoading
                        ? _c("div", { staticClass: "customer-name" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("vouchers.messages.searchingCustomer")
                              )
                            ),
                          ])
                        : _vm._e(),
                      _vm.customerNotFound
                        ? _c("div", { staticClass: "customer-name" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("vouchers.messages.customerNotFound")
                              )
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-form-group",
                {
                  staticClass: "col-md-4 col-sm-12",
                  attrs: {
                    label: _vm.$t("vouchers.inputs.startDate"),
                    state: !_vm.$v.startDate.$error,
                    "label-for": "voucher-startDate",
                  },
                },
                [
                  _c("date-picker", {
                    attrs: {
                      id: "voucher-startDate",
                      "not-before": _vm.$moment().format("YYYY-MM-DD"),
                      hide: false,
                    },
                    model: {
                      value: _vm.startDate,
                      callback: function ($$v) {
                        _vm.startDate = $$v
                      },
                      expression: "startDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  staticClass: "col-md-4 col-sm-12",
                  attrs: {
                    label: _vm.$t("vouchers.inputs.expirationDate"),
                    state: !_vm.$v.expirationDate.$error,
                    "label-for": "voucher-expirationDate",
                  },
                },
                [
                  _c("date-picker", {
                    attrs: {
                      id: "voucher-expirationDate",
                      "not-before": _vm
                        .$moment(_vm.startDate || _vm.$moment())
                        .format("YYYY-MM-DD"),
                      hide: !_vm.startDate,
                    },
                    model: {
                      value: _vm.expirationDate,
                      callback: function ($$v) {
                        _vm.expirationDate = $$v
                      },
                      expression: "expirationDate",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-form-row",
            [
              _c(
                "b-form-group",
                {
                  staticClass: "col-md-4 col-sm-12",
                  attrs: {
                    label: _vm.$t("vouchers.inputs.amountType"),
                    state: !_vm.$v.amountType.$error,
                    "label-for": "voucher-amountType",
                  },
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      id: "voucher-amountType",
                      options: _vm.amountTypeList,
                    },
                    model: {
                      value: _vm.amountType,
                      callback: function ($$v) {
                        _vm.amountType = $$v
                      },
                      expression: "amountType",
                    },
                  }),
                ],
                1
              ),
              _c("mask-input", {
                attrs: {
                  id: "voucher-amount",
                  "parent-class": "col-md-4 col-sm-12 mask-style",
                  type: "number",
                  prepend: _vm.$t("payments.labels.currency"),
                  "mask-label": _vm.$t("vouchers.inputs.amount"),
                  mask: ["#####,##", "####,##", "###,##", "##,##", "#,##"],
                  disabled: !_vm.amountType || !_vm.isFixedValue,
                  "field-state": !_vm.$v.amount.$error,
                },
                model: {
                  value: _vm.amount,
                  callback: function ($$v) {
                    _vm.amount = $$v
                  },
                  expression: "amount",
                },
              }),
              _c("mask-input", {
                attrs: {
                  id: "voucher-percentageAmount",
                  "parent-class": "col-md-4 col-sm-12 mask-style",
                  append: "%",
                  "mask-label": _vm.$t("vouchers.inputs.percentageAmount"),
                  mask: ["###", "##", "#"],
                  disabled: !_vm.amountType || _vm.isFixedValue,
                  "field-state": !_vm.$v.percentageAmount.$error,
                },
                model: {
                  value: _vm.percentageAmount,
                  callback: function ($$v) {
                    _vm.percentageAmount = $$v
                  },
                  expression: "percentageAmount",
                },
              }),
            ],
            1
          ),
          _c(
            "b-form-row",
            [
              _c(
                "b-form-group",
                {
                  staticClass: "col-md-4 col-sm-12",
                  attrs: {
                    label: _vm.$t("vouchers.inputs.usageLimit"),
                    state: !_vm.$v.usageLimit.$error,
                    "label-for": "voucher-usageLimit",
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: { id: "voucher-usageLimit", type: "number" },
                    model: {
                      value: _vm.usageLimit,
                      callback: function ($$v) {
                        _vm.usageLimit = $$v
                      },
                      expression: "usageLimit",
                    },
                  }),
                ],
                1
              ),
              _c("mask-input", {
                attrs: {
                  id: "voucher-minimumAmount",
                  "parent-class": "col-md-4 col-sm-12 mask-style",
                  type: "number",
                  prepend: _vm.$t("payments.labels.currency"),
                  "mask-label": _vm.$t("vouchers.inputs.minimumAmount"),
                  mask: ["#####,##", "####,##", "###,##", "##,##", "#,##"],
                  disabled: !_vm.amountType,
                  "field-state": !_vm.$v.minimumAmount.$error,
                },
                model: {
                  value: _vm.minimumAmount,
                  callback: function ($$v) {
                    _vm.minimumAmount = $$v
                  },
                  expression: "minimumAmount",
                },
              }),
              _c("mask-input", {
                attrs: {
                  id: "voucher-maximumAmount",
                  "parent-class": "col-md-4 col-sm-12 mask-style",
                  prepend: _vm.$t("payments.labels.currency"),
                  "mask-label": _vm.$t("vouchers.inputs.maximumAmount"),
                  mask: ["#####,##", "####,##", "###,##", "##,##", "#,##"],
                  disabled: !_vm.amountType || _vm.isFixedValue,
                  "field-state": !_vm.$v.maximumAmount.$error,
                },
                model: {
                  value: _vm.maximumAmount,
                  callback: function ($$v) {
                    _vm.maximumAmount = $$v
                  },
                  expression: "maximumAmount",
                },
              }),
            ],
            1
          ),
          _c(
            "b-form-row",
            { staticClass: "lines" },
            [
              _c(
                "b-form-group",
                {
                  staticClass: "col-md-12 col-sm-12 mb-0",
                  attrs: {
                    label: _vm.$t("vouchers.inputs.description"),
                    "label-class": "font-weight-bold",
                    "label-for": "voucher-description",
                    state: !_vm.$v.description.$error,
                  },
                },
                [
                  _c("b-form-textarea", {
                    attrs: {
                      id: "voucher-description",
                      type: "text",
                      rows: 1,
                      "max-rows": 4,
                      required: true,
                    },
                    model: {
                      value: _vm.description,
                      callback: function ($$v) {
                        _vm.description = $$v
                      },
                      expression: "description",
                    },
                  }),
                  !_vm.$v.description.minLength
                    ? _c("b-form-invalid-feedback", {
                        attrs: { "force-show": !_vm.$v.description.minLength },
                        domProps: {
                          innerHTML: _vm._s(
                            `Tamanho mínimo ${_vm.$v.description.$params.minLength.min} caracteres`
                          ),
                        },
                      })
                    : _vm._e(),
                  !_vm.$v.description.maxLength
                    ? _c("b-form-invalid-feedback", {
                        attrs: { "force-show": !_vm.$v.description.maxLength },
                        domProps: {
                          innerHTML: _vm._s(
                            `Tamanho máximo ${_vm.$v.description.$params.maxLength.max} caracteres`
                          ),
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "modal-footer" },
        [
          _c(
            "b-button",
            {
              attrs: { size: "md", variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.handleClose()
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("vouchers.buttons.cancel")) + " ")]
          ),
          _c(
            "b-button",
            {
              attrs: { size: "md", variant: "success" },
              on: {
                click: function ($event) {
                  return _vm.handleSubmit()
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("vouchers.buttons.save")) + " ")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }