<template>
  <div class="ticket">
    <div class="inner-ticket">
      <span class="ticket-number">{{ code }}</span>
    </div>
  </div>
</template>
  
  <script>
  export default {
    name: "TicketComponent",
    props: {
      code: {
        type: String,
        required: true
      }
    }
  };
  </script>
  
  <style scoped>
  .ticket {
    display: inline-block;
    padding: 0;
    position: relative;
    background-color: transparent;
    width: 240px;
  }
  
  .inner-ticket {
    background: radial-gradient(closest-side, #ffffff, gold);
    color: brown;
    font-weight: bold;
    padding: 5px 15px;
    text-align: center;
    font-family: 'Arial', sans-serif;
    position: relative;
    z-index: 1;
    border-radius: 5px;
  } 
  
  .ticket-number {
    font-size: 12px;
  }
  
  .ticket::before,
  .ticket::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: 50%;
    transform: translateY(-50%);
    border-style: solid;
  }
  
  .ticket::before {
    left: -10px;
    border-width: 7.5px 10px 7.5px 0;
    border-color: transparent gold transparent transparent;
  }
  
  .ticket::after {
    right: -10px;
    border-width: 7.5px 0 7.5px 10px;
    border-color: transparent transparent transparent gold;
  }
  </style>
  