import { i18next } from '@translate/i18n';

export const VOUCHER_TYPES = {
    GLOBAL: 'GLOBAL',
    CUSTOMER: 'CUSTOMER',
};

export const VOUCHER_AMOUNT_TYPES = {
    PERCENT: 'PERCENT',
    VALUE: 'VALUE'
};

export const VOUCHER_STATUS = {
    ACTIVE: 'ACTIVE',
    PAUSED: 'PAUSED',
    DELETED: 'DELETED',
    EXPIRED: 'EXPIRED',
    FULL_USED: 'FULL_USED'
};

export const VOUCHER_TYPES_OPTIONS = [
    { text: i18next.t('vouchers.enums.voucherType.GLOBAL'), value: VOUCHER_TYPES.GLOBAL },
    { text: i18next.t('vouchers.enums.voucherType.CUSTOMER'), value: VOUCHER_TYPES.CUSTOMER },
];

export const VOUCHER_AMOUNT_TYPES_OPTIONS = {
    PERCENT: { text: i18next.t('vouchers.enums.amountType.PERCENT'), value: VOUCHER_AMOUNT_TYPES.PERCENT },
    VALUE: { text: i18next.t('vouchers.enums.amountType.VALUE'), value: VOUCHER_AMOUNT_TYPES.VALUE }
};
